.nav-link {
    margin-right: 50px;
    margin-left: 50px;
    font-size: 17px;
    color: #ffffff !important;
}

.logo {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  margin: 0px;
}
